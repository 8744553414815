<template>
  <r-page page-title="Invoice">
    <template #page-header>
      <h2 class="page-title">Data Invoice</h2>
    </template>
    <template #action-bar>
      <div class="columns is-multiline">
        <div class="column is-2-tablet is-3-fullhd" />
        <div class="column is-8-tablet is-6-fullhd">
          <r-card v-if="getIsLoading">
            <div class="loading-card">
              <Loading :is-loading="getIsLoading" />
            </div>
          </r-card>
          <r-card v-else>
            <div class="level">
              <div class="level-left">
                <h3 class="active-package-title">
                  {{
                    activePackage.data && activePackage.data.isExpired
                      ? 'Inactive Package'
                      : 'Active Package'
                  }}
                </h3>
              </div>
              <div class="level-right">
                <div class="active-package-subtitle">
                  Active Until
                  <span>
                    {{
                      activePackage.data && activePackage.data.endSubscriptionAt
                        ? formatDate(activePackage.data.endSubscriptionAt)
                        : '-'
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="invoice-content-container">
              <div v-if="activePackage.data && !activePackage.data.isExpired">
                <div class="columns is-multiline">
                  <div class="column is-2-tablet is-1-fullhd">
                    <img src="../../assets/images/rise-invoice-icon.svg" />
                  </div>
                  <div class="column is-5-tablet is-6-fullhd">
                    <div class="is-12 invoice-content-container__title">
                      {{ activePackage.data.currentPackage.name }}
                    </div>
                    <div class="is-12 invoice-content-container__subtitle">
                      Rise Web App
                    </div>
                    <div class="is-12 invoice-content-container__max-user">
                      <b-icon icon="account" custom-size="mdi-18px" />
                      <span>
                        Max
                        {{ activePackage.data.currentPackage.maxUser }} Users
                      </span>
                    </div>
                  </div>
                  <div class="column is-5 invoice-content-container__price">
                    Rp.
                    {{
                      formatCurrency(activePackage.data.currentPackage.price)
                    }}
                    <span>/ month</span>
                  </div>
                  <div class="column is-7 refund-text">
                    <b-button
                      label="Refund Subscription"
                      type="is-primary"
                      outlined
                      class="button-cancel-subscription"
                      @click.native="openModalCancel"
                      :disabled="!activePackage.data.isCanBeRefunded"
                    />
                  </div>
                  <div class="column is-5 button-container">
                    <div class="is-12 button-container__pay-now">
                      <b-button
                        label="Pay Now"
                        type="is-primary"
                        expanded
                        tag="router-link"
                        to="/package-detail?mode=payNow"
                        :disabled="!activePackage.data.isRenewable"
                      />
                    </div>
                    <div class="is-12">
                      <b-button
                        label="Upgrade Package"
                        type="is-primary"
                        outlined
                        expanded
                        tag="router-link"
                        to="/subscription/package/upgrade"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="columns is-multiline">
                  <div class="column is-3">
                    <img src="../../assets/images/expired-package.svg" />
                  </div>
                  <div class="column is-9">
                    <div class="is-12 active-package-title">
                      {{ 'Sorry, your package expired :(' }}
                    </div>
                    <div class="is-12 expired-text">
                      Please make sure your already extend payment to get your
                      package back. click the link below to choose your package.
                      <a href="/subscription/package/choose" class="is-link">
                        Choose Package
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-2-tablet is-3-fullhd" />
      </div>
    </template>
    <template #page-content>
      <div class="level">
        <div class="level-left">
          <h4 class="invoice-table-title">Invoice History</h4>
        </div>
        <div class="level-right">
          <div
            class="filter-active role-access"
            v-if="packageArray.length !== 0"
          >
            Packages +{{ packageArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('package')"
              />
            </span>
          </div>
          <div class="filter-active status" v-if="statusArray.length !== 0">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('status')"
              />
            </span>
          </div>
          <b-button
            icon-left="filter-outline"
            label="Filter"
            type="is-light"
            class="mx-2"
            @click.native="openFilter()"
          ></b-button>
          <GeneralFilter
            :isFilterOpen="isFilterOpen"
            :packages="packageFilters"
            :statusList="statuses"
            @closeFilter="closeFilter"
            @filterBy="filterBy($event)"
            @reset="resetState()"
            @loadData="loadInvoiceData()"
          ></GeneralFilter>
          <b-input
            placeholder="Search"
            icon-right="magnify"
            type="is-light"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            @keydown.native.enter="searchIconClick(search)"
            :load="isInvoiceLoading"
            v-model="search"
          ></b-input>
        </div>
      </div>
      <div id="table-invoice" class="table-timeoff-type table-request-personal">
        <b-table
          :data="data ? data.data : []"
          :per-page="perPage"
          :current-page.sync="page"
          :loading="isInvoiceLoading"
          :sticky-header="stickyHeaders"
          :total="total"
          paginated
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          hoverable
          ref:table
          backend-pagination
          backend-sorting
          @sort="onSort"
          @page-change="onPageChange"
          class="table-voucher-management"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template>
            <b-table-column field="id" v-slot="props" label="Order ID" sortable>
              <span>
                {{ props.row.orderId }}
              </span>
            </b-table-column>
            <b-table-column field="date" v-slot="props" label="Date" sortable>
              <span class="is-capitalize">
                {{ props.row.date ? formatDate(props.row.date) : '-' }}
              </span>
            </b-table-column>
            <b-table-column
              field="billing-date"
              label="Billing Date Issue"
              sortable
              v-slot="props"
            >
              <span class="is-capitalize">
                {{
                  props.row.billingDateIssue
                    ? formatDate(props.row.billingDateIssue)
                    : '-'
                }}
              </span>
            </b-table-column>
            <b-table-column
              field="amount"
              label="Grand Total"
              sortable
              v-slot="props"
            >
              <span class="is-capitalize">
                Rp. {{ formatCurrency(props.row.amount) }}
              </span>
            </b-table-column>
            <b-table-column field="package" label="Package" v-slot="props">
              <span class="is-capitalize">
                {{ props.row.package }}
              </span>
            </b-table-column>
            <b-table-column field="status" label="Status" v-slot="props">
              <span
                class="invoice-status is-capitalize"
                :class="props.row.status ? props.row.status : 'is-primary'"
              >
                {{ props.row.status ? props.row.status : '-' }}
              </span>
            </b-table-column>
            <b-table-column label="Action" v-slot="props">
              <div class="center">
                <b-icon
                  icon="printer"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="printInvoice(props.row.orderId)"
                ></b-icon>
              </div>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <WarningModal
        :isModalOpen="isModalCancelOpen"
        :title="'Are you sure?'"
        :subtitle="'are you sure you want cancel subscription? All your Rise data will be lost'"
        @close="closeModalCancel"
        @run="submit"
      ></WarningModal>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'
import Loading from '@/components/Loading'

// import SubscriptionInvoiceFilter from '../../components/FilterContent/SubscriptionInvoiceFilter.vue'
import GeneralFilter from '../../components/FilterContent/GeneralFilter.vue'

import moment from 'moment-timezone'
import indexingParams from '@/views/InternalAdmin/Company/mixins/indexingParams'
import WarningModal from '../../components/WarningModal.vue'
import ApiService from '../../services/common/api.service'
export default {
  components: {
    // SubscriptionInvoiceFilter,
    WarningModal,
    GeneralFilter,
    Loading,
  },
  mixins: [indexingParams],
  data() {
    return {
      data: [],
      activePackage: [],
      perPage: 10,
      page: 1,
      isInvoiceLoading: false,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      isPaginated: true,
      stickyHeaders: true,
      total: 0,
      lastPage: 1,
      search: '',
      from: 0,
      activeUntil: null,
      isModalCancelOpen: false,
      reason: '',
      reasonLists: [
        'Choosing the wrong package',
        `Can't use this apps`,
        `Price don't match`,
        'Other reason',
      ],
      otherReason: '',
      packageFilters: [
        {
          title: 'Small Business',
          value: 'small business',
          isSelected: false,
        },
        {
          title: 'Medium Business',
          value: 'medium business',
          isSelected: false,
        },
        {
          title: 'Company',
          value: 'company',
          isSelected: false,
        },
        {
          title: 'Enterprise',
          value: 'enterprise',
          isSelected: false,
        },
      ],
      statuses: [
        {
          title: 'Waiting',
          value: 'waiting',
          isSelected: false,
        },
        {
          title: 'Cancelled',
          value: 'cancelled',
          isSelected: false,
        },
        {
          title: 'Unpaid',
          value: 'unpaid',
          isSelected: false,
        },
        {
          title: 'Paid',
          value: 'paid',
          isSelected: false,
        },
      ],
      statusArray: [],
      packageArray: [],
      isFilterOpen: false,
    }
  },
  async mounted() {
    this.setInvoice([])
    await this.loadInvoiceData()
    await this.loadCurrentPackage()
  },
  computed: {
    ...mapGetters({
      getInvoice: 'subscription/getVoucherData',
      getIsLoading: 'subscription/getIsLoading',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadInvoice: 'subscription/fetchInvoiceData',
      actionLoadCurrentPackage: 'subscription/fetchCurrentPackage',
      actionRefundSubscription: 'subscription/refundSubscription',
    }),
    ...mapMutations({
      setInvoice: 'subscription/setInvoiceData',
    }),

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.setInvoice([])
      await this.loadInvoiceData()
    },

    /**
     *  Handle for change pagination
     * @param {number} page - page number
     */
    onPageChange(page) {
      this.page = page
      this.loadInvoiceData()
    },

    /**
     *  Function to fetch invoice data
     */
    async loadInvoiceData() {
      try {
        let params = [
          ...this.getParams({
            page: this.page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            search: this.search,
            additionalFilters: [this.packageArray, this.statusArray],
          }),
        ]

        let response = await this.actionLoadInvoice({
          parameter: params.join('&'),
        })
        if (response && response.status === 200) {
          this.data = response.data
        }
        this.lastPage = response.data.meta.lastPage
        this.total = response.data.meta.total
        this.from = response.data.meta.total
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
    },

    /**
     *  Function to fetch current package
     */
    async loadCurrentPackage() {
      try {
        let response = await this.actionLoadCurrentPackage()
        if (response && response.status === 200) {
          this.activePackage = response.data
        }
      } catch (e) {
        showToast(e.response.meta.message, 'is-danger', 'is-top')
      }
    },

    /**
     *  Function to apply filter via search
     */
    async searchIconClick() {
      await this.resetState()
    },

    /**
     *  Function to reset all filter state
     */
    async resetState() {
      this.statusArray = []
      this.packageArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.packageFilters.forEach((el) => (el.isSelected = false))
      this.page = 0
      this.lastPage = 0
      this.setInvoice([])
      await this.loadInvoiceData()
    },

    /**
     *  Function for print invoice
     * @param {muber} id
     */
    printInvoice(id) {
      this.$router.push({
        path: '/invoice-receipt',
        query: {
          order: id,
        },
      })
    },

    /**
     *  Function for format date
     * @param {string} str - date value
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     *  Function for format currency into Rupiah
     * @param {number} val - value
     */
    formatCurrency(val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },

    /**
     *  Handle for open filter
     */
    openFilter() {
      this.isFilterOpen = true
    },

    /**
     *  Handle for close filter
     */
    closeFilter() {
      this.isFilterOpen = false
    },

    /**
     *  Handle for open cancel modal
     */
    openModalCancel() {
      this.isModalCancelOpen = true
    },

    /**
     *  Handle for close cancel modal
     */
    closeModalCancel() {
      this.isModalCancelOpen = false
      this.reason = ''
      this.otherReason = ''
    },

    /**
     *  HandleClick for change filter
     *  @param {object} event - listener for change filter
     */
    filterBy(event) {
      let index = event.index
      const value =
        event.type === 'status'
          ? this.statusArray.indexOf(`filter_status[]=${event.item.value}`)
          : this.packageArray.indexOf(`filter_package[]=${event.item.value}`)

      if (value > -1) {
        this.addFilter({ value, index, type: event.type })
      } else {
        this.removeFilter({ value: event.item.value, index, type: event.type })
      }
    },

    /**
     *  Add filter to array
     *  @param {object} param
     */
    addFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.splice(value, 1)
        this.statuses[index].isSelected = false
      } else {
        this.packageArray.splice(value, 1)
        this.packageFilters[index].isSelected = false
      }
    },

    /**
     *  Remove filter in array
     *  @param {object} param
     */
    removeFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.push(`filter_status[]=${value}`)
        this.statuses[index].isSelected = true
      } else {
        this.packageArray.push(`filter_package[]=${value}`)
        this.packageFilters[index].isSelected = true
      }
    },

    /**
     *  Handle for clear filter by filter type
     * @param {string} type - filter type
     */
    async clearFilter(type) {
      if (type === 'status') {
        this.statusArray = []
        this.statuses.forEach((el) => (el.isSelected = false))
      } else {
        this.packageArray = []
        this.packageFilters.forEach((el) => (el.isSelected = false))
      }
      this.setInvoice([])
      await this.loadInvoiceData()
    },

    /**
     *  Handle for submit refund
     */
    async submit() {
      this.isInvoiceLoading = true
      let orderId = this.activePackage.data.latestPaidTransaction.code

      try {
        let response = await ApiService.post(
          `/api/space-roketin/payment/v1/payment/refund`,
          { order_id: orderId }
        )
        if (response && response.status === 200) {
          this.isInvoiceLoading = false
          this.closeModalCancel()
          await this.loadCurrentPackage()
          await this.loadInvoiceData()
          showToast(response.data.meta.message, 'is-success', 'is-top')
        }
      } catch (e) {
        this.closeModalCancel()
        showToast(e.response.meta.message, 'is-danger', 'is-top')
      }
    },
  },
}
</script>
