export default {
  methods: {
    getParams({
      page,
      perPage,
      sortField,
      sortOrder,
      additionalFilters,
      search,
    }) {
      let params = [
        `page=${page}`,
        `per_page=${perPage}`,
        `sort_field=${sortField}`,
        `sort_order=${sortOrder}`,
        `search=${search}`,
      ]

      if (additionalFilters && Array.isArray(additionalFilters)) {
        additionalFilters.forEach((filter) => {
          params.push(...filter)
        })
      }

      return params
    },
  },
}
